import * as yup from "yup";
import { Form, Button, Row, Col, message, Checkbox } from "antd";
import { useFormikContext, Formik } from "formik";
import { get } from "lodash";

import TextInput from "grandus-lib/components-atomic/form/TextInput";

import styles from "components/forms/Newsletter/Newsletter.module.scss";

const Newsletter = () => {
  const formProps = {
    enableReinitialize: true,
    initialValues: {
      email: "",
      privacyPolicy: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .nullable()
        .email("Zadajte email vo formáte name@domain.com")
        .required("Zadajte vašu emailovú adresu"),
      privacyPolicy: yup
        .bool()
        .oneOf([true], "Musíte súhlasiť so spracovaním osobných údajov"),
    }),
    onSubmit: async (values, { setFieldError, setErrors, resetForm }) => {
      const { email } = values;
      const reqBody = { email: email };
      try {
        const response = await fetch(`/api/lib/v1/newsletter/subscribe`, {
          method: "POST",
          body: JSON.stringify(reqBody),
        }).then((data) => data.json());

        if (response) {
          const errorMessage = get(get(response, "messages.0"), "message");
          if (!errorMessage) {
            resetForm();
            message.success(
              "Boli ste úspešne prihlásený na odber newslettra",
              5
            );
          } else {
            setFieldError("email", errorMessage);
          }
        }
      } catch (error) {
        console.error("An unexpected error happened:", error);
        setErrors(error.data.message);
      }

      return false;
    },
  };

  return (
    <Row align="middle" justify="space-between" className={styles?.newsletter}>
      <Col xs={24} md={12}>
        <h3>Newsletter</h3>
        <p>
          Neváhajte sa prihlásiť do sekcie newsletter. Môžete občas dostať
          e-mail o našich nových produktoch, správy z pozadia a získať možnosť
          vedieť o zľavách ako jedny z prvých zákazníkov.
        </p>
      </Col>
      <Col xs={24} md={{ span: 10, offset: 2 }}>
        <Formik {...formProps}>
          <NewsletterForm />
        </Formik>
      </Col>
    </Row>
  );
};

const NewsletterForm = () => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();
  return (
    <Form wrapperCol={{ span: 24 }} layout="horizontal" onFinish={handleSubmit}>
      <Row gutter={[16, 0]} align="middle">
        <Col xs={24} md={18}>
          <TextInput
            label="Email"
            name="email"
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Col>

        <Col xs={24} md={6}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isValid && isSubmitting}
              disabled={!dirty || !isValid}
            >
              Odoberať
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Item
            valuePropName="checked"
            className={styles?.agreementCheckboxWrapper}
            name="privacyPolicy"
            validateStatus={
              touched?.privacyPolicy && errors?.privacyPolicy ? "error" : ""
            }
            help={
              touched?.privacyPolicy && errors?.privacyPolicy
                ? errors?.privacyPolicy
                : ""
            }
          >
            <Checkbox onChange={handleChange}>
              Súhlasím so spracovaním osobných údajov
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Newsletter;
