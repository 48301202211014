import useSWR from "swr";
import { get, map, slice, round } from "lodash";
import Link from "next/link";
import { getCategoryLinkAttributes } from "grandus-lib/hooks/useFilter";

import styles from "./Footer.module.scss";

import NewsletterForm from "components/forms/Newsletter";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import Image from "grandus-lib/components-atomic/image/Image";

const Footer = () => {
  const { data } = useSWR(
    "/api/pages/footer",
    (url) => fetch(url).then((r) => r.json()),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const webinstance = get(data, "webinstance", {});
  const statics = get(data, "statics", []);
  const categories = get(data, "categories", []);

  return (
    <>
      <div className={styles.newsletter}>
        <div className={styles.container}>
          <NewsletterForm />
        </div>
      </div>

      <footer id={styles.footer}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              <h3>Kategórie</h3>
              <div className={styles.row}>
                <div className={`${styles.col} ${styles["no-padding"]}`}>
                  <ul>
                    {map(
                      slice(categories, 0, round(categories.length / 2)),
                      (item) => {
                        const linkProps = getCategoryLinkAttributes(
                          get(item, "urlName"),
                          "",
                          {},
                          {
                            absoluteHref: item?.externalUrl
                              ? item?.externalUrl
                              : null,
                          }
                        );
                        return (
                          <li key={`footer-category-${item?.id}`}>
                            <Link {...linkProps}>
                              <a>{get(item, "name")}</a>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
                <div className={`${styles.col} ${styles["no-padding"]}`}>
                  <ul>
                    {map(
                      slice(categories, round(categories.length / 2)),
                      (item, index) => {
                        const linkProps = getCategoryLinkAttributes(
                          get(item, "urlName"),
                          "",
                          {},
                          {
                            absoluteHref: item?.externalUrl
                              ? item?.externalUrl
                              : null,
                          }
                        );
                        return (
                          <li key={`footer-category-${item?.id}-${index}`}>
                            <Link {...linkProps}>
                              <a>{get(item, "name")}</a>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className={`${styles.col} ${styles["col-6"]}`}>
              {statics ? (
                <>
                  <h3>Informácie</h3>
                  <div className={styles.row}>
                    <div className={`${styles.col} ${styles["no-padding"]}`}>
                      <ul>
                        {map(
                          slice(statics, 0, round(statics.length / 2)),
                          (item, index) => {
                            return (
                              <li
                                key={`footer-static-page-${item?.id}-${index}`}
                              >
                                <Link
                                  href="/stranka/[id]"
                                  as={`/stranka/${get(item, "urlTitle")}`}
                                >
                                  <a>{get(item, "title")}</a>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div className={`${styles.col} ${styles["no-padding"]}`}>
                      <ul>
                        {map(
                          slice(statics, round(statics.length / 2)),
                          (item, index) => {
                            return (
                              <li
                                key={`footer-static-page-${item?.id}-${index}`}
                              >
                                <Link
                                  href="/stranka/[id]"
                                  as={`/stranka/${get(item, "urlTitle")}`}
                                >
                                  <a>{get(item, "title")}</a>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.col}`}>
              <address>
                <div>
                  <Link href="/" as={`/`}>
                    <a>
                      <Image
                        photo={get(webinstance, "logo")}
                        size={get(webinstance, "logo.id") + "/200x47"}
                        type={"png"}
                      />
                    </a>
                  </Link>
                </div>
                <a href={`tel:${get(webinstance, "globalSettings.phone")}`}>
                  {get(webinstance, "globalSettings.phone")}
                </a>
                <br />
                <a href={`mailto:${webinstance?.adminEmail}`}>
                  {webinstance?.adminEmail}
                </a>
                <br />
                <br />
                {get(webinstance, "globalSettings.name_of_company")}
                <br />
                {get(webinstance, "globalSettings.street_and_number")}
                <br />
                {get(webinstance, "globalSettings.ZIP")}{" "}
                {get(webinstance, "globalSettings.city")} <br />
                <br />
                <a
                  href={`https://maps.google.com/?q=${get(
                    webinstance,
                    "globalSettings.name_of_company"
                  )},${get(
                    webinstance,
                    "globalSettings.street_and_number"
                  )},${get(webinstance, "globalSettings.ZIP")},${get(
                    webinstance,
                    "globalSettings.city"
                  )}`}
                  className={styles["map-anchor"]}
                >
                  zobraziť na mape
                </a>
              </address>
            </div>
          </div>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles.socials}`}>
              <div className={styles["social-icons"]}>
                {get(webinstance, "globalSettings.instagram_link") ? (
                  <a href={get(webinstance, "globalSettings.instagram_link")}>
                    <InstagramOutlined />
                  </a>
                ) : (
                  ""
                )}

                {get(webinstance, "globalSettings.facebook_link") ? (
                  <a href={get(webinstance, "globalSettings.facebook_link")}>
                    <FacebookOutlined />
                  </a>
                ) : (
                  ""
                )}

                {get(webinstance, "globalSettings.youtube_link") ? (
                  <a href={get(webinstance, "globalSettings.youtube_link")}>
                    <YoutubeOutlined />
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col + " " + styles.copyright}>
              2020 © mobilonline.sk | prevádzkované eshop systémom{" "}
              <a href="https://www.grandus.sk" rel="nofollow sponsored">
                Grandus
              </a>{" "}
              od spoločnosti{" "}
              <a href="https://www.forbestclients.com" rel="nofollow sponsored">
                For Best Clients, s.r.o.
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
